import { useEffect, useState, useRef } from "react";
import "./index.css";
import Logo from "../../Assets/big-logo.svg";
import a from "../../Assets/a-con.png";
import b from "../../Assets/icon-b.png";
import c from "../../Assets/icon-c.png";
import d from "../../Assets/icon-d.png";
import e from "../../Assets/icon-e.png";
import f from "../../Assets/icon-f.png";
import g from "../../Assets/icon-g.png";
import h from "../../Assets/icon-h.png";
import TimeoutModal from "../utils";
import IdleTimer from "react-idle-timer";
import { Collapse } from "react-collapse";
function Hero({ show }) {
  const [showCollapse, setShowCollapse] = useState(false);
  let logoutTimer;
  let inputRef = useRef();

  const [showModal, setShowModal] = useState(false);

  const logout = () => {
    sessionStorage?.clear();
    localStorage.clear();
    window.location.href = "/";
  };

  const handleStayLoggedIn = () => {
    if (logoutTimer) {
      clearTimeout(logoutTimer);
      logoutTimer = null;
    }
    inputRef.current.reset();
    window.location.reload(false);
  };

  logoutTimer = () => {
    setTimeout(() => {
      logout();
    }, 380000); // 3 seconds
  };

  const onIdle = () => {
    logoutTimer();
  };

  return (
    <div>
      <div>
        <div className="fi show-web-interface">
          <br />
          <br />
          <div
            className="py-3 px-5"
            style={{ background: "white", borderBottom: "5px solid #fdb81e" }}
          >
            <div className="d-flex justify-content-between align-items-center">
              <div
                className=""
                onClick={() => {
                  window.location.href = "/edd/landing";
                }}
              >
                <img src={Logo} width="200" />
              </div>

              {show && (
                <div className="show-web-interface">
                  <div className="d-flex align-items-top">
                    <div className="pr-5">
                      <div
                        className="link-ed"
                        onClick={() => {
                          window.location.href = "/edd/uio";
                        }}
                      >
                        <span className=" pb-2  d-flex justify-content-center ">
                          <img src={a} width={"36"} className="link-ed" />
                        </span>
                        <div>UI Home</div>
                      </div>
                    </div>
                    <div className="pr-5">
                      <div className="link-ed">
                        <span className=" pb-2  d-flex justify-content-center ">
                          <img src={b} width={"36"} />
                        </span>{" "}
                        <div>Certify</div>
                      </div>
                    </div>
                    <div className="pr-5">
                      <div
                        className="link-ed"
                        onClick={() => {
                          window.location.href = "/view-payment-activity";
                        }}
                      >
                        <span className=" pb-2  d-flex justify-content-center ">
                          <img src={c} width={"41"} />
                        </span>{" "}
                        <div>Payments</div>
                      </div>
                    </div>
                    <div className="pr-5">
                      <div
                        className="link-ed"
                        onClick={() => {
                          window.location.href = "/view-claim-status";
                        }}
                      >
                        <span className=" pb-2  d-flex justify-content-center ">
                          <img src={d} width={"37"} />
                        </span>{" "}
                        <div>History</div>
                      </div>
                    </div>
                    <div className="pr-5">
                      <div className="link-ed">
                        <span className=" pb-2  d-flex justify-content-center ">
                          <img src={e} />
                        </span>{" "}
                        <div>Status</div>
                      </div>
                    </div>
                    <div className="pr-5">
                      <div
                        className="link-ed"
                        onClick={() => {
                          window.location.href = "/profile";
                        }}
                      >
                        <span className=" pb-2  d-flex justify-content-center ">
                          <img src={f} />
                        </span>{" "}
                        <div>Profile</div>
                      </div>
                    </div>
                    <div className="pr-5">
                      <div
                        className="link-ed"
                        onClick={() => {
                          window.location.href = "/bank-details";
                        }}
                      >
                        <span className=" pb-2  d-flex justify-content-center ">
                          <img src={e} />
                        </span>{" "}
                        <div>Bank Details</div>
                      </div>
                    </div>
                    <div className="">
                      <div className="link-ed">
                        <span className=" pb-2  d-flex justify-content-center ">
                          <img src={h} />
                        </span>{" "}
                        <div>Contact</div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="fi show-mobile-interface">
          <div
            className="py-2 px-3"
            style={{ background: "white", borderBottom: "5px solid #fdb81e" }}
          >
            <div className="d-flex justify-content-between align-items-center">
              <div
                className=""
                onClick={() => {
                  window.location.href = "/edd/landing";
                }}
              >
                <img src={Logo} width="100" />
              </div>
              {show && (
                <div
                  className=""
                  onClick={() => {
                    setShowCollapse(!showCollapse);
                  }}
                >
                  <i
                    className="fa fa-bars fa-2x"
                    style={{ color: "#046b99" }}
                  ></i>
                </div>
              )}
            </div>
          </div>

          <Collapse isOpened={showCollapse}>
            <div className="px-3 pt-4">
              <div
                className="pb-2"
                style={{ borderBottom: "1px solid #dddddd" }}
              >
                <div
                  className="d-flex align-items-center"
                  onClick={() => {
                    window.location.href = "/edd/uio";
                  }}
                >
                  <span className=" pb-2  d-flex justify-content-center ">
                    <img src={a} width={"30"} className="link-ed pr-1" />
                  </span>
                  <div> UI Home</div>
                </div>
              </div>
              <div
                className="pb-2"
                style={{ borderBottom: "1px solid #dddddd" }}
              >
                <div className="d-flex align-items-center">
                  <span className=" pb-2  d-flex justify-content-center ">
                    <img src={b} width={"30"} className="link-ed pr-1 pt-1" />
                  </span>
                  <div> Certify</div>
                </div>
              </div>
              <div
                className="pb-2"
                style={{ borderBottom: "1px solid #dddddd" }}
              >
                <div
                  className="d-flex align-items-center"
                  onClick={() => {
                    window.location.href = "/view-payment-activity";
                  }}
                >
                  <div className=" pb-2   d-flex justify-content-center ">
                    <img src={c} width={"30"} className="link-ed pr-1 pt-2" />
                  </div>
                  <div> Payments</div>
                </div>
              </div>
              <div
                className="pb-2"
                style={{ borderBottom: "1px solid #dddddd" }}
              >
                <div className="d-flex align-items-center">
                  <span className=" pb-2  d-flex justify-content-center ">
                    <img src={d} width={"30"} className="link-ed pr-1 pt-2" />
                  </span>
                  <div> History</div>
                </div>
              </div>
              <div
                className="pb-2"
                style={{ borderBottom: "1px solid #dddddd" }}
              >
                <div className="d-flex align-items-center">
                  <span className=" pb-2  d-flex justify-content-center ">
                    <img src={e} width={"30"} className="link-ed pr-1 pt-2" />
                  </span>
                  <div> Status</div>
                </div>
              </div>
              <div
                className="pb-2"
                style={{ borderBottom: "1px solid #dddddd" }}
              >
                <div
                  className="d-flex align-items-center"
                  onClick={() => {
                    window.location.href = "/profile";
                  }}
                >
                  <span className=" pb-2  d-flex justify-content-center ">
                    <img src={f} width={"30"} className="link-ed pr-1" />
                  </span>
                  <div> Profile</div>
                </div>
              </div>
              <div
                className="pb-2"
                style={{ borderBottom: "1px solid #dddddd" }}
              >
                <div
                  className="d-flex align-items-center"
                  onClick={() => {
                    window.location.href = "/bank-details";
                  }}
                >
                  <span className=" pb-2  d-flex justify-content-center ">
                    <img src={e} width={"30"} className="link-ed pr-1 pt-2" />
                  </span>
                  <div> Bank Details</div>
                </div>
              </div>
            </div>
          </Collapse>
        </div>
      </div>
      <IdleTimer
        ref={inputRef}
        element={document}
        stopOnIdle={true}
        onIdle={onIdle}
        timeout={380000}
      />

      {/* <TimeoutModal
        showModal={showModal}
        setShowModal={setShowModal}
        togglePopup={togglePopup}
        handleStayLoggedIn={handleStayLoggedIn}
        logout={logout}
      /> */}
    </div>
  );
}

export default Hero;

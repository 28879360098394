import { useState, useRef, useEffect } from "react";
import TopHead from "../../../Components/Header/top-head";
import Hero from "../../../Components/Header/Hero2";
import Footer from "../../../Components/Footer/index";
import { Button } from "../../../Components/Button/Index";
import { Input } from "../../../Components/Input/Date";
import axios from "axios";
import config from "../../../config";
function Landing() {
  const [info, setInfo] = useState("");

  const getInfo = () => {
    axios
      .get(`${config.baseUrl}/ed-info/${sessionStorage?.getItem("user_id")}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setInfo(response?.data?.user);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getInfo();
  }, []);
  return (
    <div className="" style={{ background: "white", height: "100%" }}>
      <TopHead show={true} />
      <Hero show={true} />
      <br />
      <section>
        <div className="pb-2 pad-xo">
          <div className="row">
            <div className="col-12">
              <h1
                className="public-font"
                style={{
                  fontSize: "38.4px",
                  fontWeight: 500,
                  lineHeight: "46.08px",
                  color: "rgb(51,51,51)",
                }}
              >
                Personal Profile
              </h1>
              <p className="pt-3">
                Your Personal Profile contains your personal information and
                preferences. You can update your contact information and
                language preferences. You can even go paperless by changing your
                correspondence and certification method under Personal
                Preferences.
              </p>
              <p className="">
                If you need to update your name or date of birth, you will have
                to contact the EDD and speak to a representative.
              </p>
              <br />
              <h1
                className="public-font pt-2"
                style={{
                  fontSize: "31.04px",
                  fontWeight: 500,
                  lineHeight: "37.248px",
                  color: "rgb(51,51,51",
                }}
              >
                Personal Information
              </h1>
              <div
                className="pt-2"
                style={{
                  fontFamily: 'Source Sans Pro", sans-serif',
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "23.2px",
                  color: "rgb(51,51,51)",
                }}
              >
                Name
              </div>
              <div className="pt-1">{info?.firstname + " " +  info?.lastname}</div>
              <br />
              <div
                style={{
                  fontFamily: 'Source Sans Pro", sans-serif',
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "23.2px",
                  color: "rgb(51,51,51)",
                }}
              >
                Date of Birth (MM/DD/YYYY)
              </div>
              <div className="pt-1">{info?.dob}</div>
              <br />
              <div>Contact Us to modify this information.</div>
              <br />
              <h1
                className="public-font pb-3"
                style={{
                  fontSize: "31.04px",
                  fontWeight: 500,
                  lineHeight: "37.248px",
                  color: "rgb(51,51,51",
                }}
              >
                Contact Information
              </h1>
              <div>
                <div
                  style={{
                    fontFamily: 'Source Sans Pro", sans-serif',
                    fontSize: "16px",
                    fontWeight: "700",
                    lineHeight: "23.2px",
                    color: "rgb(51,51,51)",
                  }}
                >
                  Mailing Address
                </div>
                <div className="pt-1">{info?.mailAddress1}</div>
                <div>{info?.mailAddress2}</div>
                <div>United States</div>
                <br />
                <div
                  style={{
                    fontFamily: 'Source Sans Pro", sans-serif',
                    fontSize: "16px",
                    fontWeight: "700",
                    lineHeight: "23.2px",
                    color: "rgb(51,51,51)",
                  }}
                >
                  Residential Address
                </div>
                <div className="pt-1">{info?.residentialAddress1}</div>
                <div>{info?.residentialAddress2}</div>
                <div>United States</div>
                <br />
                <div
                  style={{
                    fontFamily: 'Source Sans Pro", sans-serif',
                    fontSize: "16px",
                    fontWeight: "700",
                    lineHeight: "23.2px",
                    color: "rgb(51,51,51)",
                  }}
                >
                  Primary Phone Number
                </div>
                <div className="pt-1">Cell: {info?.phone}</div>
                <br />
                <div
                  style={{
                    fontFamily: 'Source Sans Pro", sans-serif',
                    fontSize: "16px",
                    fontWeight: "700",
                    lineHeight: "23.2px",
                    color: "rgb(51,51,51)",
                  }}
                >
                  Alternate Phone Number
                </div>
                <div
                  style={{
                    fontFamily: 'Source Sans Pro", sans-serif',
                    fontSize: "16px",
                    fontWeight: "700",
                    lineHeight: "23.2px",
                    color: "rgb(51,51,51)",
                  }}
                >
                  Email
                </div>
                <div className="pt-1">{sessionStorage?.getItem('email')}</div>
                <div className="pt-2">
                  Visit Contact Information to modify this information.
                </div>
              </div>
              <br />
              <br />
              <h1
                className="public-font pb-3"
                style={{
                  fontSize: "31.04px",
                  fontWeight: 500,
                  lineHeight: "37.248px",
                  color: "rgb(51,51,51",
                }}
              >
                Personal Preferences
              </h1>
              <div
                style={{
                  fontFamily: 'Source Sans Pro", sans-serif',
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "23.2px",
                  color: "rgb(51,51,51)",
                }}
              >
                Spoken/Sign Language
              </div>
              <div className="pt-1">English</div>
              <br />
              <div
                style={{
                  fontFamily: 'Source Sans Pro", sans-serif',
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "23.2px",
                  color: "rgb(51,51,51)",
                }}
              >
                Written Language
              </div>
              <div className="pt-1">
                The language you select will be used for UI Online messages and
                texts.
              </div>
              <div className="pt-1">English</div>
              <br />
              <div
                style={{
                  fontFamily: 'Source Sans Pro", sans-serif',
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "23.2px",
                  color: "rgb(51,51,51)",
                }}
              >
                Correspondence Method
              </div>
              <div className="pt-1">E-mail</div>
              <div className="pt-1">
                (Note: Certain UI Online documents must be mailed regardless of
                the chosen correspondence method. Changing your correspondence
                method for UI Online does not impact your myEDD account
                correspondence method, which will continue to be email.)
              </div>
              <br />
              <div
                style={{
                  fontFamily: 'Source Sans Pro", sans-serif',
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "23.2px",
                  color: "rgb(51,51,51)",
                }}
              >
                Only certify using UI Online or EDD Tele-CertSM
              </div>
              <div className="pt-1">Yes</div>
              <div className="pt-1">
                If you select yes, the EDD will not mail the paper continued
                form (DE 4581) to you. Customers on the Partials or Work Sharing
                claims are unable to certify for benefits online at this time
                but can access the many other features of UI Online.
              </div>
              <div className="pt-1">
                Note: It may be necessary to send some documents via U.S. mail.
              </div>
              <br />
              <div>Visit Personal Preferences to modify this information.</div>
              <br />
              <h1
                className="public-font pb-3"
                style={{
                  fontSize: "31.04px",
                  fontWeight: 500,
                  lineHeight: "37.248px",
                  color: "rgb(51,51,51",
                }}
              >
                Benefit Payment Option
              </h1>
              <div
                style={{
                  fontFamily: 'Source Sans Pro", sans-serif',
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "23.2px",
                  color: "rgb(51,51,51)",
                }}
              >
                Your Payment Option
              </div>
              <div className="pt-1">Direct Deposit</div>
              <br />
              <div>
                Visit Update Your Payment Option to modify this information.
              </div>
              <br />
              <h1
                className="public-font pb-3"
                style={{
                  fontSize: "31.04px",
                  fontWeight: 500,
                  lineHeight: "37.248px",
                  color: "rgb(51,51,51",
                }}
              >
                Form 1099G Preference
              </h1>
              <div className="">Form 1099G</div>
              <div className="pt-1">Paper</div>
              <div className="pt-3">
                To go paperless, visit Form 1099G Preference to opt out of
                receiving paper copies of your Form 1099G by December 27.
              </div>
              <br />
              <h1
                className="public-font pb-3"
                style={{
                  fontSize: "31.04px",
                  fontWeight: 500,
                  lineHeight: "37.248px",
                  color: "rgb(51,51,51",
                }}
              >
                Security Profile
              </h1>
              <div className="pt-2">
                To update your Password, Security Questions, and/or Personal
                Image, return to myEDD by selecting myEDD at the top of the
                screen.
              </div>
              <br />
              <Button
                onClick={() => {
                  window.location.href = "/edd/landing";
                }}
                text="UI Home"
                className="str-dark px-5"
              />

              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Landing;

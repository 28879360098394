import { useState, useRef, useEffect } from "react";
import "./index.css";
import TopHead from "../../../Components/Header/top-head";
import Hero from "../../../Components/Header/Hero2";
import Footer from "../../../Components/Footer/index";
import { Button } from "../../../Components/Button/Index";
import { useHistory } from "react-router-dom";
import { Collapse } from "react-collapse";
import config from "../../../config";
import Logo from "../../../Assets/big-logo.svg";
import axios from "axios";
function Landing() {
  const [openResource, setOpenResource] = useState(false);
  const [openResource2, setOpenResource2] = useState(false);
  const [info, setInfo] = useState({});
  const history = useHistory();

  const getInfo = () => {
    axios
      .get(`${config.baseUrl}/ed-info/${sessionStorage?.getItem("user_id")}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setInfo(response?.data?.user);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getInfo();
  }, []);

  return (
    <div className="" style={{ background: "white", height: "100%" }}>
      <TopHead show={true} />
      <Hero show={true} />
      <br />
      <section>
        <div className="pb-2 pad-xo">
          <div className="row">
            <div className="col-12">
              <section className="d-flex justify-content-center align-items-center show-web-interface">
                <div className="col-8">
                  <h1
                    className="public-font"
                    style={{
                      fontSize: "38.4px",
                      fontWeight: 500,
                      lineHeight: "46.08px",
                      color: "rgb(51,51,51)",
                    }}
                  >
                    {info?.backDateTitle}
                  </h1>

                  <div className="mt-4">
                    <div
                      style={{
                        border: "1px solid #f0f0f0",
                        background: "white",
                      }}
                    >
                      <div className="py-3 px-3">
                        <br />
                        <img src={Logo} width="200" />
                        <br />
                        <br />
                        <div
                          style={{
                            fontSize: "16px",
                            fontWeight: 400,
                            lineHeight: "22.4px",
                            color: "rgb(51,51,51)",
                          }}
                        >
                          Dear {sessionStorage?.getItem('name')},
                        </div>
                        <div className="mb-3">
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: 400,
                              lineHeight: "22.4px",
                              color: "rgb(117,117,117)",
                            }}
                          >
                            {info?.backDateContent}
                          </div>
                        </div>
                        <br />
                        <ul className="container pl-5 ml-4">
                          <li className="pb-3"><span className="text-white">test</span></li>
                          <li className="pb-3"><span className="text-white">test</span></li>
                          <li className="pb-3"><span className="text-white">test</span></li>
                          <li className="pb-3"><span className="text-white">test</span></li>
                          <li className="pb-3"><span className="text-white">test</span></li>
                        </ul>
                                                <p><b>Note</b>:  {info?.backDateReq}</p>

                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="d-flex justify-content-center align-items-center show-mobile-interface">
                <div className="col-12">
                  <h1
                    className="public-font"
                    style={{
                      fontSize: "38.4px",
                      fontWeight: 500,
                      lineHeight: "46.08px",
                      color: "rgb(51,51,51)",
                    }}
                  >
                    {info?.backDateTitle}
                  </h1>

                  <div className="mt-4">
                    <div
                      style={{
                        border: "1px solid #f0f0f0",
                        background: "white",
                      }}
                    >
                      <div className="py-3 px-3">
                        <br />
                        <img src={Logo} width="200" />
                        <br />
                        <br />
                        <div
                          style={{
                            fontSize: "16px",
                            fontWeight: 400,
                            lineHeight: "22.4px",
                            color: "rgb(51,51,51)",
                          }}
                        >
                          Dear {sessionStorage?.getItem('name')},
                        </div>
                        <div className="mb-3">
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: 400,
                              lineHeight: "22.4px",
                              color: "rgb(117,117,117)",
                            }}
                          >
                            {info?.backDateContent}
                          </div>
                        </div>
                        <br />
                        <ul className="container pl-5 ml-4">
                          <li className="pb-3"><span className="text-white">test</span></li>
                          <li className="pb-3"><span className="text-white">test</span></li>
                          <li className="pb-3"><span className="text-white">test</span></li>
                          <li className="pb-3"><span className="text-white">test</span></li>
                          <li className="pb-3"><span className="text-white">test</span></li>
                        </ul>
                        <p><b>Note</b>:  {info?.backDateReq}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <br />
              <Button
                onClick={() => {
                  window.location.href = "/edd/landing";
                }}
                text="UI Home"
                className="str-dark px-5"
              />
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Landing;

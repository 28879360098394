import { useState, useRef, useEffect } from "react";
import "./index.css";
import TopHead from "../../../Components/Header/top-head";
import Hero from "../../../Components/Header/Hero2";
import Footer from "../../../Components/Footer/index";
import { Button } from "../../../Components/Button/Index";
import { Input } from "../../../Components/Input/Date";
import { useHistory } from "react-router-dom";
import axios from "axios";
import config from "../../../config";
function Landing() {
  const [openResource, setOpenResource] = useState(false);
  const [info, setInfo] = useState({});
  const [openResource2, setOpenResource2] = useState(false);
  const [paytext, setPayText] = useState("")

  const history = useHistory();


   const getInfoP = () => {
    axios
      .get(
        `${config.baseUrl}/getPaymentText/${sessionStorage?.getItem("user_id")}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        setPayText(response?.data?.payments?.paymentText);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getInfo = () => {
    axios
      .get(
        `${config.baseUrl}/ed-payments/${sessionStorage?.getItem("user_id")}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        setInfo(response?.data?.user);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getInfo();
    getInfoP()
  }, []);

  return (
    <div className="" style={{ background: "white", height: "100%" }}>
      <TopHead show={true} />
      <Hero show={true} />
      <br />
      <section>
        <div className="pb-2 px-5">
          <div className="row">
            <div className="col-12">
              <h1
                className="public-font"
                style={{
                  fontSize: "38.4px",
                  fontWeight: 500,
                  lineHeight: "46.08px",
                  color: "rgb(51,51,51",
                }}
              >
                Payment Activity
              </h1>
              <p className="pt-3">
                To view your federal tax form, visit Form 1099G.
              </p>
              <p className="">
                <span style={{ color: "#d65438", fontWeight: 700 }}>*</span>
                Required Field
              </p>
              <Button text="Claim Status Tracker" className="str-dark mt-2" />
              <br />
              <br />

              <h1
                className="public-font pt-2"
                style={{
                  fontSize: "31.04px",
                  fontWeight: 500,
                  lineHeight: "37.248px",
                  color: "rgb(51,51,51",
                }}
              >
                Search Payment History
              </h1>
              <p className="pt-2">
                Enter a date range to view information about payments issued
                during the selected date range.
              </p>
              <div className="row mb-5">
                <div className="col-12">
                  <div className="show-web-interface">
                    <Input
                      type="date"
                      placeholder=""
                      label="From Date"
                      style={{ width: "420px" }}
                    />
                  </div>
                  <div className="show-mobile-interface">
                    <Input
                      type="date"
                      placeholder=""
                      label="From Date"
                      className="w-100"
                    />
                  </div>

                  <div
                    style={{
                      fontSize: "12.8px",
                      fontWeight: 400,
                      color: "rgb(104,113,120)",
                      lineHeight: "18.56px",
                    }}
                  >
                    MM/DD/YYYY
                  </div>
                  <div className="mt-3">
                    <div className="show-web-interface">
                      <Input
                        type="date"
                        placeholder=""
                        label="To Date"
                        style={{ width: "420px" }}
                      />
                    </div>

                    <div className="show-mobile-interface">
                      <Input
                        type="date"
                        placeholder=""
                        label="From Date"
                        className="w-100"
                      />
                    </div>
                    <div
                      style={{
                        fontSize: "12.8px",
                        fontWeight: 400,
                        color: "rgb(104,113,120)",
                        lineHeight: "18.56px",
                      }}
                    >
                      MM/DD/YYYY
                    </div>
                  </div>

                  <br />
                  <div className="mb-3 show-web-interface">
                    <div className="d-flex align-items-center">
                      <Button text="Search" className="nude-payment mr-3" />
                      <Button text="Reset" className="nude-payment" />
                    </div>
                  </div>
                  <div className="mb-3 show-mobile-interface">
                    <div className="">
                      <Button text="Search" className="nude-payment mr-3" />
                      <Button text="Reset" className="nude-payment mt-3" />
                    </div>
                  </div>
                  <div>
                    <h1
                      className="public-font pt-2"
                      style={{
                        fontSize: "31.04px",
                        fontWeight: 500,
                        lineHeight: "37.248px",
                        color: "rgb(51,51,51)",
                      }}
                    >
                      Payment History
                    </h1>
                    <p className="pt-2">{paytext}</p>
                    <br />

                    {info?.length < 1 && (
                      <section
                        style={{ height: "500px", background: "#f2f2f2" }}
                      >
                        <div className="d-flex justify-content-center align-items-center h-100">
                          <p>No Payment Records.</p>
                        </div>
                      </section>
                    )}
                    {info?.length > 0 && (
                      <div                             style={{ width: "100%", overflowX: "auto" }}
>
                        <table class="table table-striped">
                          <thead
                          >
                            <tr>
                              <th scope="col" style={{color:'#046b99'}}>View</th>
                              <th scope="col" style={{color:'#046b99'}}>Payment Issued Date</th>
                                                            <th scope="col" style={{color:'#046b99'}}>Payment ID</th>
                              <th scope="col" style={{color:'#046b99'}}>Payment Issued</th>
                              <th scope="col" style={{color:'#046b99'}}>Payment Method</th>
                              <th scope="col" style={{color:'#046b99'}}>Payment Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {info?.map((d) => {
                              return (
                                <tr>
                                  <td><u style={{borderBottom:'1px solid #046b99'}}>Details</u></td>
                                  <td>{d?.paymentIssuedDate}</td>
                                                                    <td>{d?.paymentID}</td>
                                  <td>{d?.paymentIssues}</td>
                                  <td>{d?.paymentMethod}</td>
                                  <td>{d?.paymenStatus}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>{" "}
                      </div>
                    )}
                    <br />
                    <Button
                      onClick={() => {
                        window.location.href = "/edd/landing";
                      }}
                      text="UI Home"
                      className="str-dark px-5"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Landing;

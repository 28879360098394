import { useState, useRef, useEffect } from "react";
import "./index.css";
import TopHead from "../../../Components/Header/top-head";
import Hero from "../../../Components/Header/Hero2";
import Footer from "../../../Components/Footer/index";
import { Button } from "../../../Components/Button/Index";
import { useHistory } from "react-router-dom";
import { Collapse } from "react-collapse";
import config from "../../../config"
import axios from "axios";
function Landing() {
  const [openResource, setOpenResource] = useState(false);
  const [openResource2, setOpenResource2] = useState(false);
  const [info, setInfo] = useState({})
  const history = useHistory();

  const getInfo = () => {
    axios
      .get(`${config.baseUrl}/ed-info/${sessionStorage?.getItem("user_id")}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setInfo(response?.data?.user);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getInfo();
  }, []);

  return (
    <div className="" style={{ background: "white", height: "100%" }}>
      <TopHead show={true} />
      <Hero show={true} />
      <br />
      <section>
        <div className="pb-2 pad-xo">
          <div className="row">
            <div className="col-12">
              <section className="d-flex justify-content-center align-items-center show-web-interface">
                <div className="col-7">
                  <h1
                    className="public-font"
                    style={{
                      fontSize: "38.4px",
                      fontWeight: 500,
                      lineHeight: "46.08px",
                      color: "rgb(51,51,51)",
                    }}
                  >
                    Claim Status Tracker
                  </h1>
                  <br />
                  <div
                    className="suns-font"
                    style={{
                      fontSize: "31.04px",
                      fontWeight: 500,
                      lineHeight: "32.248px",
                      color: "rgb(51,51,51)",
                    }}
                  >
                    Claim Status
                  </div>
                  <br />
                  <div
                    className="suns-font"
                    style={{
                      fontSize: "24px",
                      fontWeight: 500,
                      lineHeight: "28.8px",
                      color: "rgb(51,51,51)",
                    }}
                  >
                    No Weeks Available to Certify
                  </div>
                  <p className="pt-3">
                    You currently have no weeks available to certify for
                    benefits.
                  </p>
                  <div
                    className="suns-font"
                    style={{
                      fontSize: "24px",
                      fontWeight: 500,
                      lineHeight: "28.8px",
                      color: "rgb(51,51,51)",
                    }}
                  >
                    Your Next Steps
                  </div>
                  <br />
                  <ul className="pl-5">
                    <li>
                      Check your UI Online homepage and inbox for the latest
                      updates.
                    </li>
                    <li>
                      Check your mail. We may send requests for additional
                      information. Make sure to respond as requested to avoid
                      delays in processing your claim.
                    </li>
                    <li>
                      If it’s been more than 30 days since you last certified
                      for benefits, you must reopen your claim before you can
                      certify.
                    </li>
                  </ul>
                  <div
                    className="suns-font"
                    style={{
                      fontSize: "24px",
                      fontWeight: 500,
                      lineHeight: "28.8px",
                      color: "rgb(51,51,51)",
                    }}
                  >
                    EDD Next Steps
                  </div>
                  <br />
                  <ul className="pl-5">
                    <li>
                      We will email you when you have weeks available to certify
                      for benefits.
                    </li>
                  </ul>
                  <br />
                  <div
                    className="suns-font pt-3"
                    style={{
                      fontSize: "31.04px",
                      fontWeight: 500,
                      lineHeight: "32.248px",
                      color: "rgb(51,51,51)",
                    }}
                  >
                    Claim Details
                  </div>
                  <div className="mt-4">
                    <div
                      style={{
                        border: "4px solid #f0f0f0",
                        background: "white",
                      }}
                    >
                      <div className="py-3 px-3">
                        <div className="mb-3">
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: 400,
                              lineHeight: "22.4px",
                              color: "rgb(117,117,117)",
                            }}
                          >
                            Claim Type
                          </div>
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: 400,
                              lineHeight: "22.4px",
                              color: "rgb(51,51,51)",
                            }}
                          >
                            Unemployment Insurance (UI)
                          </div>
                        </div>
                        <div className="mb-3">
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: 400,
                              lineHeight: "22.4px",
                              color: "rgb(117,117,117)",
                            }}
                          >
                            Benefit Year
                          </div>
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: 400,
                              lineHeight: "22.4px",
                              color: "rgb(51,51,51)",
                            }}
                          >
                            {info?.benefitYear}
                          </div>
                        </div>
                        <div className="mb-3">
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: 400,
                              lineHeight: "22.4px",
                              color: "rgb(117,117,117)",
                            }}
                          >
                            Claim Balance
                          </div>
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: 400,
                              lineHeight: "22.4px",
                              color: "rgb(51,51,51)",
                            }}
                          >
                           {info?.claimBalance}
                          </div>
                        </div>
                        <div className="mb-3">
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: 400,
                              lineHeight: "22.4px",
                              color: "rgb(117,117,117)",
                            }}
                          >
                            Weekly Benefit Amount
                          </div>
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: 400,
                              lineHeight: "22.4px",
                              color: "rgb(51,51,51)",
                            }}
                          >
                            {info?.weeklyBenefit}
                          </div>
                        </div>
                        <div className="mb-3">
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: 400,
                              lineHeight: "22.4px",
                              color: "rgb(117,117,117)",
                            }}
                          >
                            Last Payment Issued
                          </div>
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: 400,
                              lineHeight: "22.4px",
                              color: "rgb(51,51,51)",
                            }}
                          >
                            {info?.lastPayment}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>


              <section className="d-flex justify-content-center align-items-center show-mobile-interface">
                <div className="col-12">
                  <h1
                    className="public-font"
                    style={{
                      fontSize: "38.4px",
                      fontWeight: 500,
                      lineHeight: "46.08px",
                      color: "rgb(51,51,51)",
                    }}
                  >
                    Claim Status Tracker
                  </h1>
                  <br />
                  <div
                    className="suns-font"
                    style={{
                      fontSize: "31.04px",
                      fontWeight: 500,
                      lineHeight: "32.248px",
                      color: "rgb(51,51,51)",
                    }}
                  >
                    Claim Status
                  </div>
                  <br />
                  <div
                    className="suns-font"
                    style={{
                      fontSize: "24px",
                      fontWeight: 500,
                      lineHeight: "28.8px",
                      color: "rgb(51,51,51)",
                    }}
                  >
                    No Weeks Available to Certify
                  </div>
                  <p className="pt-3">
                    You currently have no weeks available to certify for
                    benefits.
                  </p>
                  <div
                    className="suns-font"
                    style={{
                      fontSize: "24px",
                      fontWeight: 500,
                      lineHeight: "28.8px",
                      color: "rgb(51,51,51)",
                    }}
                  >
                    Your Next Steps
                  </div>
                  <br />
                  <ul className="pl-5">
                    <li>
                      Check your UI Online homepage and inbox for the latest
                      updates.
                    </li>
                    <li>
                      Check your mail. We may send requests for additional
                      information. Make sure to respond as requested to avoid
                      delays in processing your claim.
                    </li>
                    <li>
                      If it’s been more than 30 days since you last certified
                      for benefits, you must reopen your claim before you can
                      certify.
                    </li>
                  </ul>
                  <div
                    className="suns-font"
                    style={{
                      fontSize: "24px",
                      fontWeight: 500,
                      lineHeight: "28.8px",
                      color: "rgb(51,51,51)",
                    }}
                  >
                    EDD Next Steps
                  </div>
                  <br />
                  <ul className="pl-5">
                    <li>
                      We will email you when you have weeks available to certify
                      for benefits.
                    </li>
                  </ul>
                  <br />
                  <div
                    className="suns-font pt-3"
                    style={{
                      fontSize: "31.04px",
                      fontWeight: 500,
                      lineHeight: "32.248px",
                      color: "rgb(51,51,51)",
                    }}
                  >
                    Claim Details
                  </div>
                  <div className="mt-4">
                    <div
                      style={{
                        border: "4px solid #f0f0f0",
                        background: "white",
                      }}
                    >
                      <div className="py-3 px-3">
                        <div className="mb-3">
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: 400,
                              lineHeight: "22.4px",
                              color: "rgb(117,117,117)",
                            }}
                          >
                            Claim Type
                          </div>
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: 400,
                              lineHeight: "22.4px",
                              color: "rgb(51,51,51)",
                            }}
                          >
                            Unemployment Insurance (UI)
                          </div>
                        </div>
                        <div className="mb-3">
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: 400,
                              lineHeight: "22.4px",
                              color: "rgb(117,117,117)",
                            }}
                          >
                            Benefit Year
                          </div>
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: 400,
                              lineHeight: "22.4px",
                              color: "rgb(51,51,51)",
                            }}
                          >
                            {info?.benefitYear}
                          </div>
                        </div>
                        <div className="mb-3">
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: 400,
                              lineHeight: "22.4px",
                              color: "rgb(117,117,117)",
                            }}
                          >
                            Claim Balance
                          </div>
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: 400,
                              lineHeight: "22.4px",
                              color: "rgb(51,51,51)",
                            }}
                          >
                           {info?.claimBalance}
                          </div>
                        </div>
                        <div className="mb-3">
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: 400,
                              lineHeight: "22.4px",
                              color: "rgb(117,117,117)",
                            }}
                          >
                            Weekly Benefit Amount
                          </div>
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: 400,
                              lineHeight: "22.4px",
                              color: "rgb(51,51,51)",
                            }}
                          >
                            {info?.weeklyBenefit}
                          </div>
                        </div>
                        <div className="mb-3">
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: 400,
                              lineHeight: "22.4px",
                              color: "rgb(117,117,117)",
                            }}
                          >
                            Last Payment Issued
                          </div>
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: 400,
                              lineHeight: "22.4px",
                              color: "rgb(51,51,51)",
                            }}
                          >
                            {info?.lastPayment}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <br />
              <Button
                onClick={() => {
                  window.location.href = "/edd/landing";
                }}
                text="UI Home"
                className="str-dark px-5"
              />
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Landing;
